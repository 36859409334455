/*******************************
         Site Overrides
*******************************/

.ui.button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
  font-family: @fontName;
  color: white;
  background: @greyDark;

  &:hover {
    color: white;
  }
}

.ui.button:disabled {
  opacity: 0.5 !important;
}