/*******************************
         Site Overrides
*******************************/

.ui.container {
  padding: 0 0 2rem 0;
}

@media only screen and (min-width: 900px){
  .ui.container {
    width: 100%;
    padding: 0 9rem 2rem 9rem;
  }
}

.ui.container.left-container {
  margin-left: 0 !important;
}