/*******************************
         Site Overrides
*******************************/

.ui.input{
  min-width: 30rem;
  font-size: 2rem;

  > input {
    &, &:focus {
      border-radius: 0.6rem;
      background-color: #fafafa;
    }
    padding: 1.2rem;
    font-weight: bold;
  }

  input::placeholder {
    color: @placeholderColorInput;
    font-weight: normal;
  }
}

.ui.disabled.input {
  opacity: @disabledOpacity;
  
  > input {
    background: @disabledColor;
  } 
}