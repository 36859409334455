/*******************************
         Site Overrides
*******************************/

.ui.modal, .ui.mini.modal {
  border-radius: 1.6rem;

  > .header:not(.ui) {
    font-size: 2rem;
    padding: 3.4rem 3.2rem;
    border-bottom: 0;
    border-radius: 1.6rem;
  }
  > .content {
    font-size: 1.8rem;
    padding: 0 3.2rem;
  }
  > .actions {
    background: @white;
    border-top: 0;
    padding: 4rem 3.2rem 2.4rem;
    border-radius: 1.6rem;
  }
  > .close {
    top: 2.2rem;
    right: 2.2rem;
    color: @grey;
  }
}

  .ui.modals.dimmer.active {
    opacity: 0 !important;

    &:last-of-type {
      opacity: 1 !important;
    }
  }
