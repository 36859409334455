/*******************************
         Site Overrides
*******************************/

.ui.checkbox.error label {
  color: @red;
}

.ui.checkbox.disabled {
  pointer-events: none;
}

@toggleSize: 2rem;
.ui.checkbox.toggle {
  &.fitted {
    width: 4.3rem;
  }

  &.error input:checked ~ label {
    color: @red !important;
  }
  
  > input {
    height: @toggleSize;
    width: 100%;

    &:checked ~ label:after {
      left: 2.2rem;
    }
  }

  > label {
    min-height: @toggleSize;
    padding-left: 5.5rem;
    &:before {
      height: @toggleSize;
      width: 4.2rem;
    }
    &:after {
      height: @toggleSize;
      width: @toggleSize;
    }
  }
}